<template>
  <div class="app-container">
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="fileTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="文件名称"  align="center">
          <template #default="scope">{{scope.row.fileName}}</template>
        </el-table-column>
        <el-table-column label="文件内容" align="center">
          <template #default="scope"><img style="height: 80px" :src="scope.row.filePic" @click="handlePictureEnlarge(scope.row.filePic)"></template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template #default="scope">{{scope.row.strTime}}</template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template #default="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog v-model="dialogVisiblePic" title="图片">
      <img :src="enlargedImageUrl" alt="Enlarged Image" style="max-width: 100%; display: block; margin: auto;">
    </el-dialog>

    <el-dialog
        :title="isEditPicFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="fileModel"
               ref="fileFrom"
               label-width="150px"
               size="small">
        <el-form-item label="文件：">
          <el-upload ref="uploadPicForm" v-model="fileModel.filePic" drag multiple
                     accept="image/*"
                     action="#"
                     :file-list="fileListShowPic"
                     :auto-upload="false"
                     :before-remove="handleRemovePic"
                     :on-change="fileChangePic"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('fileFrom')">确定</el-button>
          </span>
      </template>
    </el-dialog>

  </div>


</template>

<script>


import {
  batchDeleteFile,
  deleteFile,
  fetchList,
  uploadFile,
} from "@/api/galleryFile";


const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
};

const defaultFile = {
  filePic: null,
  fileName: null,
  releaseTime: null,
};

export default {
  name: 'File',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],


      dialogVisible: false,
      dialogVisiblePic: false,
      enlargedImageUrl: '',

      isEditPicFlag: false,
      fileModel: Object.assign({}, defaultFile),
      fileListShowPic:[],
      fileListPic:[],

    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //放大图片
    handlePictureEnlarge(imageUrl) {
      this.enlargedImageUrl = imageUrl;
      this.dialogVisiblePic = true;
    },
    //文件上传
    fileChangePic(file, fileList) {
      this.fileListPic.push(file);
      this.fileListShowPic.push({
        name: file["name"],
      })
    },
    //文件删除
    handleRemovePic(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileListPic = this.fileListPic.filter(item => item.name !== file.name);
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteFile(row.id).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //删除附件
        batchDeleteFile(this.ids).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "批量删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },

    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditPicFlag = false;
      this.fileModel = Object.assign({},defaultFile);
      if(this.$refs["uploadPicForm"]){
        this.$refs['uploadPicForm'].clearFiles()  // 清除历史文件列表
      }
      this.fileListPic = [];
      this.fileListShowPic = [];
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.fileListPic.length === 0){
            this.$message.warning('请上传文件');
            return;
          }

          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {

            const form = new FormData();
            for (let i = 0; i < this.fileListPic.length; i++) {
              form.append('files', this.fileListPic[i].raw);
            }
            uploadFile(form).then(response=>{
              if (response.code === '0') {
                /*this.$message({
                  message: '提交成功',
                  type: 'success',
                });*/
              }
              this.dialogVisible =false;
              this.getTableList();
            });
          });
        }
      });
    },
  }
}
</script>

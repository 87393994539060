import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/gallery/list',
    method : 'post',
    data : data
  })
}

//全部
export function allGallery() {
  return request({
    url : '/gallery/allGallery',
    method : 'get',
  })
}

//删除
export function deleteFile(data) {
  return request({
    url : '/gallery/deleteGallery',
    method : 'post',
    data : data
  })
}


//批量删除
export function batchDeleteFile(data) {
  return request({
    url : '/gallery/batchDeleteGallery',
    method : 'post',
    data : data
  })
}

//上传文件
export function uploadFile(form) {
  const data = form
  return request({
    url: '/gallery/upload',
    method: 'post',
    headers: {'Content-Type':'multipart/form-data'},
    data
  })
}
